var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-view__base-wrap"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isTableLoading)?_c('TmpLogoOverlay',{staticClass:"fixed"}):_vm._e()],1),_c('FormulateForm',{key:_vm.key,attrs:{"values":_vm.data},on:{"submit":_vm.handleSave}},[_c('v-container',{staticClass:"table-view__breadcrumbs-wrap pb-0"},[_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbs}})],1),_c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[(_vm.isTableLoading)?_c('TmpBlock',{attrs:{"height":"32"}}):_c('h1',{staticClass:"table-view__title"},[_vm._v(_vm._s(_vm.viewTitle))])],1)])],1)],1),_c('v-container',{staticClass:"table-view mt-6 mb-16"},[_c('v-row',{staticClass:"settings__wrap"},[_c('v-col',{staticClass:"pt-0"},[(_vm.isTableLoading)?_c('div',_vm._l((10),function(index){return _c('TmpBlock',{key:index,staticClass:"mb-4",attrs:{"height":"72"}})}),1):_c('v-expansion-panels',{attrs:{"multiple":"","accordion":"","value":_vm.openedPanels},on:{"update:value":function($event){_vm.openedPanels=$event}}},[_vm._l((_vm.templates),function(template){return [(_vm.isShowPanel(template.code))?_c('Panel',{key:template.id,attrs:{"name":template.name,"dataEducationId":template.code}},[_c('div',{staticClass:"settings__wrap-items"},[_vm._l((template.settings),function(setting){return [_c('SettingItemWrap',{key:setting.code},[(
                          [_vm.TYPES.GUID, _vm.TYPES.STRING, _vm.TYPES.NUMBER, _vm.TYPES.LEFT_DAYS_TIME, _vm.TYPES.CRON].includes(setting.type) &&
                          !setting.defaultValues.length &&
                          !_vm.isSpecialCodeForSelect(setting.code)
                        )?_c('div',{staticClass:"text-item__wrap"},[_c('v-tooltip',{attrs:{"z-index":"200","fixed":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"settings__label mb-1"},'span',attrs,false),on),[_vm._v(_vm._s(setting.name))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(setting.description))])]),_c('TextFilter',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask(setting.type, setting.code)),expression:"mask(setting.type, setting.code)"}],attrs:{"disabled":_vm.isDisabled(setting.code),"pattern":_vm.pattern(setting.type),"id":setting.id,"title":setting.name,"tooltip":setting.description,"type":!_vm.isPassword(setting.code) || !!_vm.passwordIsShowObject[setting.code] ? _vm.type(setting.type) : 'password'},on:{"change":function($event){return _vm.changeField(setting.type)}},model:{value:(_vm.data[setting.id]),callback:function ($$v) {_vm.$set(_vm.data, setting.id, $$v)},expression:"data[setting.id]"}}),(_vm.isPassword(setting.code))?_c('v-tooltip',{attrs:{"fixed":"","top":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"text-item__icon",on:{"click":function($event){return _vm.handlePasswordChangeType(setting.code)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(!!_vm.passwordIsShowObject[setting.code] ? "mdi-eye-off" : "mdi-eye"))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(!!_vm.passwordIsShowObject[setting.code] ? "Скрыть пароль" : "Показать пароль"))])]):_vm._e()],1):_vm._e(),(
                          ([_vm.TYPES.STRING, _vm.TYPES.NUMBER, _vm.TYPES.LIST].includes(setting.type) && setting.defaultValues.length) ||
                          _vm.isSpecialCodeForSelect(setting.code)
                        )?_c('div',{staticClass:"select-item__wrap"},[_c('SelectCard',{attrs:{"required":"","isMultiple":_vm.isMultipleSelectType(setting.code) || _vm.isComplexType(setting.code),"is-disabled":false,"title":setting.name,"tooltip":setting.description,"z-index":200,"id":setting.code,"items":_vm.convertItems(setting.defaultValues)},on:{"change":function($event){return _vm.changeSelect($event, setting)}},model:{value:(_vm.data[setting.id]),callback:function ($$v) {_vm.$set(_vm.data, setting.id, $$v)},expression:"data[setting.id]"}}),([_vm.vipnetListCode, _vm.smevListCode].includes(setting.code))?_c('v-tooltip',{attrs:{"z-index":"200","fixed":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"select-item__icon",on:{"click":_vm.resendCerts}},'v-icon',attrs,false),on),[_vm._v("mdi-file-certificate -outline")])]}}],null,true)},[_c('div',[_vm._v("Перезапросить Vipnet сертификаты")])]):_vm._e()],1):_vm._e(),([_vm.TYPES.BOOLEAN].includes(setting.type))?_c('CheckboxElement',{attrs:{"title":setting.name,"code":setting.code,"tooltip":setting.description},on:{"change":function($event){return _vm.onChangeCheckbox(setting, $event)}},model:{value:(_vm.data[setting.id]),callback:function ($$v) {_vm.$set(_vm.data, setting.id, $$v)},expression:"data[setting.id]"}}):_vm._e(),([_vm.TYPES.FILE].includes(setting.type))?_c('div',{staticClass:"d-flex flex-column"},[_c('v-tooltip',{attrs:{"z-index":"200","fixed":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(setting.name))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(setting.description || setting.name))])]),(_vm.filesMetadata[setting.id])?_c('div',{staticClass:"d-flex my-1 setting__file-name"},[_vm._v(" "+_vm._s(_vm.getFileName(setting.id))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-row"},[_c('v-btn',{staticClass:"footer__btn mr-2",attrs:{"height":"40","color":"primary","disabled":!_vm.defaultData[setting.id],"loading":_vm.isExportFileLoading},on:{"click":function($event){return _vm.downloadFile(_vm.defaultData[setting.id])}}},[_vm._v(" Скачать ")]),_c('v-file-input',{staticClass:"setting__file",attrs:{"counter":"","clear":"","placeholder":"Загрузить файл","outlined":"","show-size":1000},on:{"change":function($event){return _vm.uploadFile(setting, $event)}}})],1)],1):_vm._e(),([_vm.TYPES.BUTTON].includes(setting.type))?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"z-index":"200","fixed":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',_vm._g(_vm._b({staticClass:"footer__btn",attrs:{"height":"40","color":"primary","loading":_vm.isLoadingIds[setting.id]},on:{"click":function($event){return _vm.clickButton(setting.id)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(setting.name)+" ")])],1)]}}],null,true)},[_c('div',[_vm._v(_vm._s(setting.description || setting.name))])])],1):_vm._e()],1)]})],2)]):_vm._e()]})],2)],1)],1)],1),(_vm.isShowFooter)?_c('Footer',{attrs:{"isShowSelectedLength":false}},[_c('v-btn',{staticClass:"footer__btn mr-2",attrs:{"type":"submit","height":"40","data-test-id":"saveSelectButton","color":"primary"}},[_vm._v(" Сохранить ")]),_c('v-btn',{staticClass:"footer__btn",attrs:{"height":"40","data-test-id":"cancelSelectButton","outlined":"","color":"primary"},on:{"click":_vm.reset}},[_vm._v(" Отмена ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }