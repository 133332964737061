import { fields } from "@monorepo/utils/src/variables/projectsData/filestorage/fields";

export const currentFilters = (): Record<string, string> => ({
  [fields.TYPE]: "",
  [fields.GUID]: "",
  [fields.DATE_FROM]: "",
  [fields.DATE_TO]: "",
  [fields.OIK]: "",
  [fields.TK_STATUS]: "",
  [fields.FILTER_IS_DELETED_FILE]: "",
  [fields.DELETED_DATE_FROM]: "",
  [fields.DELETED_DATE_TO]: "",
});
