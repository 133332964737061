






































































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import Modal from "@monorepo/informationSecurity/src/views/Filestorage/components/Modal.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import eventBus from "@monorepo/utils/src/eventBus/index";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/filestorage/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/filestorage/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import { module as Filestorage } from "@monorepo/informationSecurity/src/store/modules/Filestorage";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/informationSecurity/src/views/Filestorage/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import SizeInfo from "./components/SizeInfo.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { IApiFilestorage, IFilestorage } from "@monorepo/informationSecurity/src/views/Filestorage/types/filestorage";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useGetTableLibs from "@monorepo/informationSecurity/src/views/Filestorage/composables/useGetTableLibs";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import { convertApiItemToUi } from "@monorepo/informationSecurity/src/views/Filestorage/utils/convertApiItemToUi";
import IdCell from "@monorepo/uikit/src/components/tableViews/IdCell.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/filestorage/fields";
import { getAuthorityByType } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/getAuthorityByType";
import { openCreationById } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/openCreationById";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/filestorage/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/informationSecurity/src/views/Filestorage/utils/defaultCurrentFilters";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

const SECTION_NAME = "filestorage";
export const fileStatuses = [
  {
    title: "Активный",
    value: false,
    bool: false,
  },
  {
    title: "Удалён",
    value: true,
    bool: true,
  },
];

export const deleteTypes = [
  {
    title: "Удален администратором",
    value: "BY_USER",
  },
  {
    title: "Удален системой",
    value: "AUTOMATIC",
  },
];

export default defineComponent({
  name: "FilestorageView",
  components: {
    TableSettings,
    ExportButton,
    ToggleTableCellsContainer,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    Modal,
    AutoRefreshButton,
    Footer,
    ExportFooterButton,
    IdCell,
    SizeInfo,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      showEadModal: false,
      pluralFormTitles: ["Файл", "Файла", "Файлов"],
      convertApiItemToUi,
      tableHeaders,
      fields,
      filtersElements,
      setCurrentFilters,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "cells",
      "isOpenFilters",
      "openedId",
      "isSelectAll",
      "selectedIds",
      "infiniteId",
      "data",
      "totalLength",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
      "objectTypes",
    ]),
    objectTypesSelectItems(): { title: string; value: string }[] {
      return convertToSelectItems(this.objectTypes, "name", "code");
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        objectTypesSelectItems: this.objectTypesSelectItems,
        tkStatuses: convertToSelectItems(["Валидный", "Невалидный", "В обработке"]),
        fileStatuses,
        deleteTypes,
      };
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.DATA_STORAGE_EXPORT);
    },
    isShowDelete(): boolean {
      return isAuthorityExist(this.user, authorities.DATA_STORAGE_DELETE);
    },
    isShowFooter(): boolean {
      return (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) && this.isShowExport;
    },
    dataObject(): Record<string, IApiFilestorage> {
      return (this.data || []).reduce((result: Record<string, IApiFilestorage>, item: IApiFilestorage) => {
        result[item.id] = item;
        return result;
      }, {});
    },
    isShowDeleteBtn(): boolean {
      const selected = Object.entries(this.selectedIds)
        .filter(([, value]) => Boolean(value))
        .map(([key]) => key);
      return (
        !!selected.length &&
        !this.isSelectAllElement &&
        selected
          .map((id: string) => this.dataObject[id])
          .every(
            (item: IApiFilestorage) =>
              ((item?.attributes as Record<string, Record<string, unknown>>)?.TK_PROCESSING_STATUS?.value as string) !== "В обработке"
          )
      );
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId) && this.data.find((element: IFilestorage) => element.id == this.openedId);
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
    isAuthorityExist() {
      return (event: ReturnType<typeof convertApiItemToUi>) => {
        const authorities = getAuthorityByType(event, "objectType");
        const authoritiesObject = this.user?.authorities.reduce((result: { [key: string]: boolean }, item: { authority: string }) => {
          result[item.authority || ""] = true;
          return result;
        }, {});
        return !!authorities.find((key: string) => authoritiesObject[key]);
      };
    },
    isSelectedPreviewEad(): boolean {
      const dataIdsObj = this.data.reduce((result: Record<string, Record<string, unknown>>, current: Record<string, unknown>) => {
        result[current.id as string] = current;
        return result;
      }, {});
      return Object.entries(this.selectedIds)
        .filter(([key, value]) => !!value)
        .map(([key]) => dataIdsObj[key])
        .some((item) => {
          return item?.attributes?.OBJECT_TYPE?.value === "PREVIEW_EAD";
        });
    },
    objectTypeTitle() {
      return (objectTypeCode: string) => {
        return this.objectTypes.find((item: { code: string; name: string }) => item.code === objectTypeCode)?.name;
      };
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, [
      "getEventList",
      "getExportData",
      "addOpenedId",
      "setIsOpenFilters",
      "setAutorefresh",
      "deleteElements",
      "refreshScroll",
    ]),
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.data.length) {
        this.addOpenedId(this.data[0].id);
      }
    },
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
    openById(event: ReturnType<typeof convertApiItemToUi>) {
      if (!this.isAuthorityExist(event)) {
        return;
      }
      const path = openCreationById(event, "objectType", "objectId");
      if (path) {
        this.$router.push(path);
      }
    },
    openDeleteModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: () =>
            this.deleteElements(
              Object.entries(this.selectedIds)
                .filter(([key, value]) => !!value)
                .map(([key]) => key)
            ),
          title: this.isSelectedPreviewEad
            ? "Среди выбранных файлов присутствует изображение предпросмотра. При удалении данного типа файла будут удалены все связанные изображения. Вы уверены, что хотите удалить все файлы предпросмотра по данному документу? "
            : `Вы уверены, что хотите удалить файл?`,
          ...(this.isSelectedPreviewEad ? { heightModal: 300 } : {}),
        })
      );
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          if (event.id) {
            this.$store.dispatch("filestorage/addOpenedId", event.id);
            this.toggleModal(true);
          }
          break;
        case fields.OIK:
        case fields.OIK_NAME: {
          this.moveByAuthorities(header, "/dictionaries/oik", {
            code: event.oikCode as string,
            isOpenModal: "1",
          });
          break;
        }
        default:
          break;
      }
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const moveByAuthorities = useMoveByAuthorities(root);

    useInitTableStoreModule(root, SECTION_NAME, Filestorage as Module<unknown, unknown>);

    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders, true).then(() => {
      useRefreshTable(root, SECTION_NAME);
    });

    useSetStartFilters(root, SECTION_NAME, []).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
    });
    useGetTableLibs(root);

    return {
      filtersLoaded,
      sectionName,
      moveByAuthorities,
    };
  },
});
