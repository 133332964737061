import { v4 as uuid } from "uuid";
import { fields } from "./fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Идентификатор",
    tooltip: "Идентификатор",
    defaultWidth: 150,
    value: fields.ID,
    isLink: false,
    isSorted: true,
    isHandle: true,
    isHiddenByDefault: false,
    id: uuid(),
  },
  {
    text: "Тип объекта",
    tooltip: "Тип объекта (Входящий ТК, Исходящий ТК, Служебный ТК МЭДО, Квитанция АК, PDF)",
    defaultWidth: 180,
    value: fields.TYPE,
    isLink: false,
    isSorted: false,
    isHandle: true,
    isHiddenByDefault: false,
    id: uuid(),
    slotName: "objectType",
  },
  {
    text: "Идентификатор объекта",
    tooltip: "Идентификатор объекта в ЦХЭД",
    defaultWidth: 150,
    value: fields.GUID,
    isLink: false,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    slotName: "idCell",
  },
  {
    text: "Дата размещения объекта в ХД ЦХЭД",
    tooltip: "Дата размещения объекта в ХД ЦХЭД",
    defaultWidth: 180,
    value: fields.DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Код ОИК",
    tooltip: "ОИК (если есть)",
    defaultWidth: 180,
    value: fields.OIK,
    linkRights: [authorities.OIK_LIST],
    isLink: true,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Название ОИК",
    tooltip: "ОИК (если есть)",
    defaultWidth: 220,
    value: fields.OIK_NAME,
    linkRights: [authorities.OIK_LIST],
    isLink: true,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Размер файла",
    tooltip: "Размер файла",
    defaultWidth: 180,
    value: fields.SIZE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус ТК",
    tooltip: "Статус ТК",
    defaultWidth: 180,
    value: fields.TK_STATUS,
    //isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата удаления файла",
    tooltip: "Дата удаления файла",
    defaultWidth: 180,
    value: fields.DELETED_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус файла",
    tooltip: "Статус файла",
    defaultWidth: 150,
    value: fields.IS_DELETED_FILE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Способ удаления файла",
    tooltip: "Способ удаления файла",
    defaultWidth: 180,
    value: fields.DELETE_TYPE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];
