import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & {
    fieldFilters: {
      createDateFrom?: string;
      createDateTo?: string;
      deleteDateFrom?: string;
      deleteDateTo?: string;
    };
  }
): void => {
  formDateFilter(resultFilters, "createDate");
  formDateFilter(resultFilters, "deleteDate");
};
