export interface IFilestorageFields {
  ID: string;
  GUID_ID: string;
  TYPE: string;
  GUID: string;
  IS_OPEN_GUID: string;
  DATE: string;
  DATE_FROM: string;
  DATE_TO: string;
  OIK: string;
  OIK_NAME: string;
  TK_STATUS: string;
  SIZE: string;
  DELETED_DATE: string;
  DELETED_DATE_FROM: string;
  DELETED_DATE_TO: string;
  IS_DELETED_FILE: string;
  DELETE_TYPE: string;

  FILTER_IS_DELETED_FILE: string;
}

export const fields: IFilestorageFields = {
  ID: "id",
  GUID_ID: "guid",
  TYPE: "objectType",
  GUID: "objectId",
  DATE: "created",
  IS_OPEN_GUID: "isOpenObjectId",
  DATE_FROM: "createDateFrom",
  DATE_TO: "createDateTo",
  OIK: "oikCode",
  OIK_NAME: "oikName",
  TK_STATUS: "tkProcessingStatus",
  SIZE: "size",
  DELETED_DATE: "deleteDate",
  DELETED_DATE_FROM: "deleteDateFrom",
  DELETED_DATE_TO: "deleteDateTo",
  IS_DELETED_FILE: "deleted",
  DELETE_TYPE: "deleteType",

  FILTER_IS_DELETED_FILE: "isDeleted",
};
