import { Module } from "vuex";
import { IRootState } from "@monorepo/informationSecurity/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import store from "@monorepo/app/src/store";
import { IAuthState } from "@monorepo/authorization/src/store/modules/auth";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/informationSecurity/src/views/Filestorage/utils/convertFiltersToApi";
import { IFilestorage, IApiFilestorage, IFilestorageMeta } from "@monorepo/informationSecurity/src/views/Filestorage/types/filestorage";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import axios from "axios";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

type error = {
  response?: { data?: { message?: string; error?: { text: string } } };
};

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  meta: IFilestorageMeta | null;
  data: IFilestorage[];
  infiniteId: string;
  sectionCells: ISection;
  section?: Sections;
  isOpenFilters: boolean;
  autorefresh: boolean;
  cells: string[];
  searchTemplates: ISearchTemplate[];
  libraries: {
    oiks: string[];
    statuses: string[];
    objectTypes: { code: string; name: string }[];
  };
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {
    created: SORT_TYPE.DESC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {},
  initMessagesLength: 50,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_FILE_STORAGE_CSV_LIST;
    case "XLSX":
      return QUERY_PATH.GET_FILE_STORAGE_XLS_LIST;
    default:
      return QUERY_PATH.GET_FILE_STORAGE_PDF_LIST;
  }
};

const getDefaultState = (): ITableState => ({
  filters: defaultFilters(),
  infiniteId: new Date().toString(),
  totalLength: 0,
  meta: null,
  data: [],
  cells: [],
  searchTemplates: [],
  section: Sections.FILESTORAGE,
  sectionCells: {} as ISection,
  isOpenFilters: false,
  autorefresh: false,
  libraries: {
    oiks: [],
    statuses: [],
    objectTypes: [],
  },
  isTableLoading: false,
  isLoadingToggleFilters: false,
  isLoadingChangeAutorefresh: false,
});

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    ...baseMutations,
    setFilters(state: ITableState, payload: ReturnType<typeof defaultFilters>) {
      state.filters = payload;
    },
    resetState(state: ITableState) {
      Object.assign(state, getDefaultState());
    },
    addStatusesLib(state: ITableState, payload: string[]) {
      state.libraries.statuses = payload || [];
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    addObjectTypes(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.objectTypes = payload;
    },
    addData(
      state: ITableState,
      payload: {
        data: IFilestorage[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
    addMeta(state: ITableState, payload: IFilestorageMeta) {
      state.meta = payload;
    },
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
  },
  actions: {
    ...baseActions,
    async getScreenshot(info, payload: string) {
      const token = (store?.state?.auth as IAuthState)?.user?.details?.token;

      let screenUrl;
      await fetch(`${getFullPath(QUERY_PATH.GET_FILE_FROM_FILE_STORAGE)}?uuid=${payload}&origin=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          const blob = await response.blob();
          screenUrl = URL.createObjectURL(blob);
        })
        .catch((err) => {
          console.log(err);
        });

      return screenUrl;
    },
    async getElement(info, payload: string) {
      const { data } = await getQuery<IApiFilestorage>(`${QUERY_PATH.GET_FILE_STORAGE}/${payload}`);
      return data || {};
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
        meta,
      } = await getQuery<IApiFilestorage[]>(QUERY_PATH.GET_FILE_STORAGE, params, true, "", "page").finally(() => {
        commit("setTableLoading", false);
      });
      if (meta) {
        commit("addMeta", meta);
      }
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async refreshEventList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        meta,
      } = await getQuery<IApiFilestorage[]>(
        QUERY_PATH.GET_FILE_STORAGE,
        {
          ...params,
          offset: 0,
        },
        true,
        "",
        "page"
      );

      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      if (meta) {
        commit("addMeta", meta);
      }
      return { data: null };
    },
    async getObjectTypes({ commit, state }) {
      checkExistLibrary(state.libraries.objectTypes, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_FILE_STORAGE_OBJECT_TYPES);
        commit("addObjectTypes", data);
      });
    },
    async deleteElements({ dispatch }, ids: number[]): Promise<boolean> {
      try {
        await axios.delete(getFullPath(QUERY_PATH.DELETE_SELCTED_TK), { params: { ids: (ids || []).join(",") } });
        showNotification("Файлы успешно удалены", NOTIFICATION_STATUS.SUCCESS);
        dispatch("setSelectedIds", {});
        dispatch("refreshScroll");
        dispatch("addOpenedId", null);
        return true;
      } catch (e) {
        showNotification(
          (e as unknown as error).response?.data?.error?.text || (e as unknown as error).response?.data?.message || "Ошибка удаления."
        );
        return false;
      }
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
        root: true,
      });
    },
  },
  getters: {
    ...baseGetters,
    objectTypes(state: ITableState) {
      return state.libraries.objectTypes;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    meta(state: ITableState) {
      return state.meta;
    },
  },
};
