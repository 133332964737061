

















































import { defineComponent } from "@vue/composition-api";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { mapActions } from "vuex";
import { getFullPath } from "@monorepo/utils/src/api/utils";

export default defineComponent({
  props: {
    bugReportId: {
      type: Number,
      default: null,
    },
    screenUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      scale: 1,
    };
  },
  computed: {
    scaleInput: {
      get(): number {
        return Math.trunc(this.scale * 100);
      },
      set(val: string): void {
        this.scale = +val / 100;
        const imgDiv = document.getElementById("imgDiv") as HTMLElement;
        if (imgDiv) {
          imgDiv.scrollTo(0, 0);
        }
      },
    },
    screenStyleObj(): Record<string, string> {
      return {
        transform: `scale(${this.scale})`,
        "transform-origin": "top left",
      };
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("bugReportView", ["getScreenshot"]),
    closeModal() {
      this.$emit("close");
    },
    downloadScreen() {
      this.openNewWindow(getFullPath(`${getFullPath(QUERY_PATH.GET_FILE_FROM_FILE_STORAGE)}?uuid=${this.bugReportId}&origin=true`));
    },
  },
});
