import { v4 as uuid } from "uuid";
import { fields } from "./fields";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

export const modalElements: IModalElementType[] = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    tooltip: "Идентификатор",
    className: "info-modal__element",
    value: fields.ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Тип объекта",
    tooltip: "Тип объекта (Входящий ТК, Исходящий ТК, Служебный ТК МЭДО, Квитанция АК, PDF)",
    className: "info-modal__element",
    value: fields.TYPE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор объекта",
    tooltip: "Идентификатор объекта в ЦХЭД",
    className: "info-modal__element",
    value: fields.GUID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата размещения объекта в ХД ЦХЭД",
    tooltip: "Дата размещения объекта в ХД ЦХЭД",
    className: "info-modal__element",
    value: fields.DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    linkRights: [authorities.OIK_LIST],
    isLink: true,
    title: "Код ОИК",
    tooltip: "ОИК (если есть)",
    className: "info-modal__element",
    value: fields.OIK,
  },
  {
    id: uuid(),
    isEdited: false,
    linkRights: [authorities.OIK_LIST],
    isLink: true,
    title: "Название ОИК",
    tooltip: "ОИК (если есть)",
    className: "info-modal__element",
    value: fields.OIK_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Размер файла",
    tooltip: "Размер файла",
    className: "info-modal__element",
    value: fields.SIZE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус ТК",
    tooltip: "Статус ТК",
    className: "info-modal__element",
    value: fields.TK_STATUS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата удаления файла",
    tooltip: "Дата удаления файла",
    className: "info-modal__element",
    value: fields.DELETED_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Способ удаления файла",
    tooltip: "Способ удаления файла",
    className: "info-modal__element",
    value: fields.DELETE_TYPE,
  },
];

export const fullModalElements = [...modalElements];
