export interface IDefaultValue {
  id: number;
  value: string;
  displayName: string;
}

export interface ITemplateElement {
  id: number;
  code: string;
  name: string;
  type: TYPES;
  description: string;
  defaultValues: IDefaultValue[];
  complexityType: COMPLEXITY_TYPES;
}

export interface ISectionElement {
  id: number;
  code: string;
  name: string;
  description: string;
  settings: ITemplateElement[];
}

export interface IValueElement {
  id: number;
  code: string;
  value: string;
}

export enum TYPES {
  STRING = "STRING",
  NUMBER = "INTEGER",
  BOOLEAN = "BOOLEAN",
  GUID = "GUID",
  LEFT_DAYS_TIME = "LEFT_DAYS_TIME",
  CRON = "CRON",
  LIST = "LIST",
  FILE = "FILE",
  BUTTON = "BUTTON",
}

export enum COMPLEXITY_TYPES {
  COMPLEX = "COMPLEX",
  SIMPLE = "SIMPLE",
}
