import { fields } from "./fields";

export const filtersElements = [
  {
    value: fields.GUID,
    title: "Идентификатор объекта в ЦХЭД",
    type: "text",
    width: 263,
  },
  {
    value: fields.TYPE,
    title: "Тип объекта",
    type: "select",
    selectItemsField: "objectTypesSelectItems",
    width: 263,
    className: "filters__isj-type mt-4",
  },
  {
    value: fields.OIK,
    title: "ОИК",
    type: "text",
    width: 263,
  },
  {
    value: fields.DATE,
    dateValues: [fields.DATE_FROM, fields.DATE_TO],
    title: "Дата размещения объекта в ХД ЦХЭД",
    type: "datePeriod",
    width: 244,
  },
  {
    value: fields.TK_STATUS,
    title: "Статус ТК",
    type: "select",
    selectItemsField: "tkStatuses",
    width: 263,
  },
  {
    value: fields.DELETED_DATE,
    dateValues: [fields.DELETED_DATE_FROM, fields.DELETED_DATE_TO],
    title: "Дата удаления файла",
    type: "datePeriod",
    width: 244,
  },
  {
    value: fields.FILTER_IS_DELETED_FILE,
    title: "Статус файла",
    type: "select",
    selectItemsField: "fileStatuses",
    width: 285,
  },
  {
    value: fields.DELETE_TYPE,
    title: "Способ удаления файла",
    type: "select",
    selectItemsField: "deleteTypes",
    width: 285,
  },
];
