
























































































import { defineComponent } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { mapActions, mapGetters } from "vuex";
import { IApiFilestorage, IFilestorage } from "@monorepo/informationSecurity/src/views/Filestorage/types/filestorage";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { openCreationById } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/openCreationById";
import { fullModalElements as modalElements } from "@monorepo/utils/src/variables/projectsData/filestorage/modalElements";
import { get } from "lodash";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/filestorage/viewTitle";
import { fields } from "@monorepo/utils/src/variables/projectsData/filestorage/fields";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { convertApiItemToUi } from "@monorepo/informationSecurity/src/views/Filestorage/utils/convertApiItemToUi";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { getAuthorityByType } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/getAuthorityByType";
import Screenshot from "./Screenshot.vue";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import useFormLinkByAuthoritiesModal from "@monorepo/utils/src/composables/useFormLinkByAuthoritiesModal";
import { ModalType } from "@monorepo/utils/src/variables/modalType";

export default defineComponent({
  name: "FilestorageInfoModal",
  components: {
    CardModalInfoElement,
    CardModalStatusChip,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    Screenshot,
    CardModalChapter,
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      screenUrl: "",
      isShowScreenshot: false,
      isPendingLoadUrl: false,
      element: {} as ReturnType<typeof convertApiItemToUi>,
      fields,
      modalElements,
      cardMode: CardMode,
      section: Sections.FILESTORAGE,
      viewUniqKey,
      openedPanels: [0, 1, 2],
      isLoading: false,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      handler(
        this: {
          isShowScreenshot: boolean;
          addQueryOpenedId: () => void;
          getElement: (val: string) => Promise<IApiFilestorage>;
          element: ReturnType<typeof convertApiItemToUi>;
          isLoading: boolean;
          isShowAnimation: boolean;
        },
        value
      ) {
        this.isShowScreenshot = false;
        if (value) {
          this.isLoading = true;
          this.addQueryOpenedId();
          this.getElement(value)
            .then((data: IApiFilestorage) => {
              this.element = convertApiItemToUi(data || {});
            })
            .catch(console.error)
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("filestorage", ["data", "openedId", "objectTypes"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isShowDelete(): boolean {
      return isAuthorityExist(this.user, authorities.DATA_STORAGE_DELETE) && this.element?.tkProcessingStatus !== "В обработке";
    },
    statusColor(): string {
      const status: string = (this.element as IFilestorage)[fields.IS_DELETED_FILE as keyof IFilestorage] as string;
      switch (status) {
        case "Активный":
          return "#00A459";
        case "Удалён":
          return "#D34039";
        default:
          return "";
      }
    },
    toggleScreenshotTitle(): string {
      return this.isShowScreenshot ? "Закрыть снимок экрана" : "Открыть снимок экрана";
    },
    toggleScreenshotIcon(): string {
      return this.isShowScreenshot ? "mdi-arrow-right" : "mdi-arrow-left";
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.DATA_STORAGE_EXPORT);
    },
    getItemElement(): unknown {
      return (key: string) => {
        switch (key) {
          case "objectType": {
            const item = get(this.element, key);
            return this.objectTypeTitle(item);
          }
          default:
            return get(this.element, key);
        }
      };
    },
    isAuthorityExistInGUID(): boolean {
      const authorities = getAuthorityByType(this.element, "objectType");
      const authoritiesObject = this.user?.authorities.reduce((result: { [key: string]: boolean }, item: { authority: string }) => {
        result[item.authority || ""] = true;
        return result;
      }, {});
      return !!authorities.find((key: string) => authoritiesObject[key]);
    },
    isPreviewEadType(this: { element: Record<string, unknown> }) {
      return this.element?.objectType === "PREVIEW_EAD";
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("filestorage", ["changeOpenedId", "getElement", "getScreenshot", "deleteElements"]),
    downloadFile() {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_FILE_FROM_FILE_STORAGE)}?uuid=${this.element.guid}&origin=true`);
    },
    objectTypeTitle(objectTypeCode: string) {
      return this.objectTypes.find((item: { code: string; name: string }) => item.code === objectTypeCode)?.name;
    },
    openItem(item: { isLink: boolean; value: string }) {
      if (item.value === fields.OIK || item.value === fields.OIK_NAME) {
        (
          this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
        ).moveByAuthorities(item, "/dictionaries/oik", { code: this.element.oikCode as string, isOpenModal: "1" });
      }

      if (item.value === fields.GUID && this.isAuthorityExistInGUID) {
        this.openById();
      }
    },
    openDeleteModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: () => this.deleteElements([this.element.id]),
          title: this.isPreviewEadType
            ? "При удалении одной из страниц предпросмотра будут  удалены все связанные изображения. Вы уверены, что хотите удалить все файлы предпросмотра по данному документу?"
            : `Вы уверены, что хотите удалить файл?`,
          ...(this.isPreviewEadType ? { heightModal: 260 } : {}),
        })
      );
    },
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    async toggleScreenshot() {
      try {
        this.isShowScreenshot = !this.isShowScreenshot;
        if (this.isShowScreenshot) {
          this.isPendingLoadUrl = true;
          this.screenUrl = "";
          this.screenUrl = await this.getScreenshot(this.element.guid);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isPendingLoadUrl = false;
      }
    },
    closeModal() {
      this.$emit("close");
    },
    openById() {
      const path = openCreationById(this.element, "objectType", "objectId");
      if (path) {
        this.$router.push(path);
      }
    },
  },
  setup(props, context) {
    const { root } = context;
    useModalChangeByKeypress(root, "filestorage", Sections.FILESTORAGE, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "filestorage");
    const { resultModalElements, moveByAuthorities } = useFormLinkByAuthoritiesModal(root, modalElements);

    return {
      resultModalElements,
      addQueryOpenedId,
      moveByAuthorities,
    };
  },
});
