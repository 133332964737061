import { Module } from "vuex";
import { IRootState } from "@monorepo/informationSecurity/src/store";
import { IDefaultValue, ISectionElement, IValueElement } from "@monorepo/informationSecurity/src/views/Settings/types/templateElement";
import { getQuery } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import axios from "axios";
import { vipnetListCode, smevListCode, auditTypesToNotify } from "@monorepo/informationSecurity/src/views/Settings/constants/specialCodes";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

interface ITableState {
  templates: ISectionElement[];
  isTableLoading: boolean;
}

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: {
    templates: [],
    isTableLoading: false,
  },
  mutations: {
    saveTemplates(state: ITableState, payload: ISectionElement[]) {
      state.templates = payload;
    },
    saveAuditTypesToNotify(state: ITableState, payload: IDefaultValue[]) {
      state.templates = state.templates.map((template) => {
        return {
          ...template,
          settings: template.settings.map((setting) => {
            if (setting.code === auditTypesToNotify) {
              setting.defaultValues = payload;
            }

            return setting;
          }),
        };
      });
    },
    saveVipnetList(state: ITableState, payload: IDefaultValue[]) {
      state.templates = state.templates.map((template) => {
        return {
          ...template,
          settings: template.settings.map((setting) => {
            if ([vipnetListCode, smevListCode].includes(setting.code)) {
              setting.defaultValues = payload;
            }

            return setting;
          }),
        };
      });
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    async getAuditTypesToNotify({ commit }) {
      try {
        const { data } = await axios.get<{ data: { code: string; name: string }[] }>(QUERY_PATH.GET_AUDIT_TYPES_NOTIFY);
        const list = data.data || [];
        commit(
          "saveAuditTypesToNotify",
          list.map((item, index) => ({
            id: index,
            value: item.code,
            displayName: item.name,
          }))
        );
        return true;
      } catch (e) {
        return false;
      }
    },
    async checkTsaUri(root, tsaUri: string) {
      try {
        const { data } = await axios.post(QUERY_PATH.CHECK_TSA_URI, { tsaUri });
        return data.code === 0;
      } catch (e) {
        return false;
      }
    },
    async getVipnetCertificates({ commit }, payload: { [key: string]: string }) {
      try {
        const { data } = await axios.post<Record<string, never>>(QUERY_PATH.GET_VIPNET_CERTS, payload);
        const list: unknown[] = data.data || [];
        commit("saveVipnetList", list);
        showNotification(
          list.length ? "Список Vipnet сертификатов успешно загружен" : "Список Vipnet сертификатов не загружен",
          list.length ? NOTIFICATION_STATUS.SUCCESS : NOTIFICATION_STATUS.ERROR
        );
        return true;
      } catch (e) {
        showNotification("Ошибка загрузки списка сертификатов");
        return false;
      }
    },
    async getTemplates({ commit, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const { data } = await getQuery<ISectionElement[]>(QUERY_PATH.GET_SETTINGS_TEMPLATE).finally(() => {
        commit("setTableLoading", false);
      });
      commit("saveTemplates", data || []);
    },
    async getDataRequest() {
      const { data } = await getQuery<IValueElement[]>(QUERY_PATH.GET_SETTINGS_DATA);
      return data || [];
    },
    async saveElements(info, settings: IValueElement[]) {
      return await axios.put<Record<string, never>>(QUERY_PATH.GET_SETTINGS, settings);
    },
    async uploadFileToStorage(info, formData: FormData) {
      try {
        const { data } = await axios.post(QUERY_PATH.UPLOAD_FILE_TO_FILE_STORAGE, formData);
        return data;
      } catch (e) {
        showNotification("Ошибка загрузки файла");
      }
    },
    async getMetadata(info, uuid: string) {
      try {
        const { data } = await axios.get(`${QUERY_PATH.GET_FILE_META}`, { params: { uuid } });
        return data?.data;
      } catch (e) {
        return null;
      }
    },
  },
  getters: {
    templates: (state) => state.templates,
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
