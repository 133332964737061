






















import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";
import { IFilestorageMeta } from "@monorepo/informationSecurity/src/views/Filestorage/types/filestorage";

export default defineComponent({
  name: "SizeInfo",
  computed: {
    ...mapGetters("filestorage", ["meta"]),
    usingPercentage(): number {
      return ((this.meta as unknown as IFilestorageMeta | null)?.diskUsageInfo?.usingPercent as number) ?? 0;
    },
    freePercentage() {
      return 100 - (this.usingPercentage as number);
    },
    sizeInfoItems() {
      const infoObj = (this.meta as unknown as IFilestorageMeta | null)?.diskUsageInfo;
      return [
        {
          title: "Общий объем хранилища",
          value: `${infoObj?.totalGb || 0} Гб`,
        },
        {
          title: "Занято",
          value: `${infoObj?.usingGb || 0} Гб`,
        },
        {
          title: "Свободно",
          value: `${infoObj?.freeGb || 0} Гб`,
        },
      ];
    },
  },
});
