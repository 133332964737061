import { IFilestorage, IApiFilestorage } from "@monorepo/informationSecurity/src/views/Filestorage/types/filestorage";
import moment from "moment";
import { openCreationById } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/openCreationById";
import { getFileSize } from "@monorepo/utils/src/utils/getFileSize";

export const convertApiItemToUi = (item: IApiFilestorage): IFilestorage => ({
  id: (item.id as unknown as string) || "",
  guid: (item.guid as unknown as string) || "",
  created: item.created ? moment(item.created as string).format("YYYY-MM-DD HH:mm") : "",
  objectType: ((item.attributes as Record<string, Record<string, unknown>>).OBJECT_TYPE?.value as string) || "",
  objectId: ((item.attributes as Record<string, Record<string, unknown>>).OBJECT_ID?.value as string) || "",
  isOpenObjectId: !!openCreationById(item, "attributes.OBJECT_TYPE.value"),
  oikCode: item.attributes.OIK_CODE?.additional?.code || "",
  oikName: item.attributes.OIK_CODE?.additional?.name || "",
  tkProcessingStatus: ((item.attributes as Record<string, Record<string, unknown>>).TK_PROCESSING_STATUS?.value as string) || "",
  size: getFileSize(item.size),
  deleteDate: item.deleteDate ? moment(item.deleteDate as string).format("YYYY-MM-DD HH:mm") : "",
  deleted: item.deleted ? "Удалён" : "Активный",
  deleteType: item.deleteType || "",
});
