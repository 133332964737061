













import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Panel",
  props: {
    name: {
      type: String,
      default: "",
    },
    dataEducationId: {
      type: String,
    },
  },
});
